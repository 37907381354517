@import url('https://fonts.googleapis.com/css?family=Sen&display=swap');

.pictureBox {
  text-align: center;
  background-size: cover; /* or contain depending on what you want */
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 5px 1px #000000;
  width: 90vw;
  height: 85vh;
  display: flex;
  border-radius: 15px;
  margin: 7.5vh;
}

h3 {
    font-style: oblique;
}

#one {
    background-image: url('./img/IMG_9418.jpg');
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#two {
    background-image: url('./img/IMG_9529.jpg');
    align-items: center;
    justify-content: flex-end;
}

#three {
    background-image: url('./img/IMG_9548.jpg');
    flex-direction: column;
    height: 100vh;
}

#four {
    background-image: url('./img/IMG_9553.jpg');
}

#five {
    background-image: url("./img/IMG_9545.jpg");
}

.embed-form {
    display: flex;
    justify-content: center;
    align-items:center;
}

.reg {
    background-color: rgb(123,5,6);
    align-items: center;
    justify-content: center;
    display: flex;
    min-width: 100vw;
    min-height: 100vh;
    flex-direction: column;
    /* box-shadow: inset 0 0 10px #000000; */
}

.half {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.half2 {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.reg-background {
    background-color: rgba(255,255,255,.6);
    display: flex;
    flex-direction: column;
    height:100%;
    border-radius: 15px;
}

.infoBox {
    background-color: rgba(255,255,255,.9);
    border-radius: 20px;
    box-shadow: 0px 0px 4px 0px #000000;
    padding: 10px;
    margin: 5%;
    min-height: 80%;
}

.reg-form {
    display: flex;
    flex-direction: column;
}

.email {
}

.form-heading {
    font-size: calc(10px + 3vmin);
}

input {
    background-color: rgba(0,0,0,0);
    border-style: none;
    font-size: 20px;
}

.form-box {
    background-color: orange;
    min-height: 25vh;
    padding: 30px;
    border-radius: 15px;
}

p {
    text-align: left;
}

body {
    font-family: 'Sen', sans-serif;
}


.reg-info {
    min-height: 100vh;;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: black;
}

.intro-basic {
    background-color: rgba(255,255,255,.9);
    /* border-style: solid;
    border-width: 5px;
    border-color: rgb(123,5,6); */
    width: 75vw;
    padding: 15px;
    border-radius: 15px;
    margin: 15px;
}
.title {
    font-size: calc(10px + 7vmin);
    margin: 10px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

